import React from "react";
import PhysiotherapyImage from "../Assets/1.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content md:w-[40%] sm:w-[30%]">
        <img src={PhysiotherapyImage} alt="Physiotherapy" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          Welcome to Dr.Harish Jaglan Physiotherapy & Chiropractic Clinic, your trusted partner for comprehensive physiotherapy services. We are committed to providing personalized care that helps you recover, improve, and maintain your physical health. Our expert physiotherapists offer a range of specialized treatments tailored to meet your unique needs. Join us on this journey towards a healthier you.
        </p>

        <h4 className="about-text-title">Our Approach</h4>

        <SolutionStep
          title="Personalized Treatment Plans"
          description="Our physiotherapists create customized treatment plans designed to address your specific conditions and goals, whether recovering from injury, surgery, or managing chronic pain."
        />

        <SolutionStep
          title="Holistic Care"
          description="We emphasize a holistic approach to health, integrating manual therapy, exercise prescription, and education to optimize your physical function and well-being."
        />

        <SolutionStep
          title="Patient-Centered Focus"
          description="At Dr.Harish Jaglan Clinic, you are at the center of everything we do. We prioritize your comfort and progress, ensuring every session is productive and supportive."
        />

        
      </div>
    </div>
  );
}

export default About;
