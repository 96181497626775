import React, { lazy } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import video from "../../Assets/8313076-hd_1920_1080_30fps.mp4";
import image from "../../Assets/9403718.jpg"

const Card = lazy(() => import("../Card/index"));

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5rem 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    opacity: 0.3; /* Adjust opacity as needed */
  }
`;

const Title = styled.h1`
  color: #DA0411 ;
  display: inline-block;
  font-size: calc(1rem + 1.5vw);
  margin-top: 1.5rem;
  position: relative;
  &::before {
    content: "";
    height: 1px;
    width: 50%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0.5rem);
    border-bottom: 2px solid var(--purple);
  }
`;

const Carousal = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only Screen and (max-width: 40em) {
    width: 90vw;
    .slick-slider .slick-arrow {
      display: none;
    }
  }
  .slick-slider .slick-arrow:before {
    color: #DA0411 ;
    font-size: 1.5rem;
    @media only Screen and (max-width: 40em) {
      display: none;
    }
  }
  .slick-slider .slick-dots button:before {
    color: #DA0411 ;
    font-size: 1.5rem;
  }
  .slick-slide.slick-active {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    margin-bottom: 3rem;
  }
`;

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Section id="reviews" className="relative">
      <video autoPlay loop muted>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Title className="text-caribbeangreen-500 border-b-4 p-2 border-[#DA0411 ]">Patient's Reviews</Title>
      <Carousal className="relative z-10">
      <img className="w-[50%] mx-auto mt-10 " src={image} />
        <Slider className="lg:bottom-[12rem] md:bottom-[12rem] sm:bottom-[8rem] xs:bottom-[6rem] " {...settings}>
        <Card
            text="It was awesome experience with Dr. Harish Jaglan. I was facing lower back issues but his cup therapy gives me quick relief"
            name="Sumit Saini"
          />
          <Card
            text="Solve my shoulder Pain, I highly recommend this clinic, specifically thnx dr harish jaglan.Best physiotherapy in chandigarh"
            name="Mohit khatkar"
          />
          <Card
            text="Nice experience solve my back pain problem , Specially thanks Dr.Harish Best physiotherapy in chandigarh"
            name="Alchemist Limited"
          />
          <Card
            text="Dr.Harish Jaglan helped me a lot during physio session when i get injured and undergo ACL and meniscus surgery. best physiotherapist in tricity . Thanks a lot."
            name="Sumit Saini"
          />
          <Card
            text="Mere bhai ki back pain thi, usne yahan physiotherapy ki aur ab fit hai. Bilkul recommend karunga!"
            name="Sanchit"
          />

          <Card
            text="Physiotherapist bahut achhe hain, mujhe jaldi relief mila. Aap bhi try karo!"
            name="Priya Singh"
          />

          <Card
            text="I had knee surgery and came here for rehab. Excellent service and care!"
            name="John Doe"
          />

          <Card
            text="Yahan ke doctors ne meri sports injury ko jaldi thik kiya. Amazing experience!"
            name="Alisha Patel"
          />
        </Slider>
      </Carousal>
    </Section>
  );
};

export default Testimonials;
