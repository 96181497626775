import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAppointmentDetails as fetchDetails, updateAppointmentStatus } from "../../../../services/operations/routesAPI";
import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Stack, Step, StepLabel, Stepper } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




async function fetchAppointmentDetails(token, appointmentId) {
  try {
    const res = await fetchDetails(token, appointmentId);
    return res;
  } catch (error) {
    console.error("Could not fetch History:", error);
  }
}


export default function UpdateAppointmentStatus() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState(null);
  const [status, setStatus] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const details = await fetchAppointmentDetails(token, appointmentId);
      setOrderDetails(details?.data?.orderDetails);
      setStatus(details.status);
    }
    fetchData();
  }, [token, appointmentId]);


  useEffect(() => {
    if (orderDetails) {
      switch (orderDetails.status) {
        case "completed":
          setActiveStep(0);
          break;
        case "pending":
          setActiveStep(1);
          break;
        case "canceled":
          setActiveStep(2);
          break;
        default:
          break;
      }
    }
  }, [orderDetails]);

  const handleUpdateStatus = async () => {
    try {
      const res = await updateAppointmentStatus(token, { appointmentId: orderDetails._id, newStatus: status });
      // console.log("Updated order status:", res);
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleBackButton = () => {
    navigate("/dashboard/view-appointments");
  };

  // console.log("details are ", orderDetails?.orderDetails?.username)

  return (
    <>
      <div className="w-11/12 ">
        <div className="text-3xl text-richblack-700 border-b-2 font-semibold border-caribbeangreen-400 py-4">Update Appointment status</div>
        {orderDetails === null ? (
          <div className="grid min-h-[calc(100vh-3.5rem)] ">
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </div>
        ) : (
          <div className="my-8 text-richblack-800">
            
            <div className="border border-caribbeangreen-200 rounded-md p-4 flex flex-col w-full mt-8 justify-evenly text-maroon-100">
              <div className="flex justify-between gap-3 p-5 overflow-wrap rounded grid md:grid-cols-2 sm:grid-cols-1 text-richblack-700 bg-maroon-50 ">
                <p className="font-semibold">Appointment ID: {orderDetails?._id}</p>
                <p className="font-semibold">Date: {new Date(orderDetails.appointmentDate).toLocaleDateString()}</p>
                <p className="font-semibold">Time: {orderDetails?.appointmentTime }</p>
                <p className="font-semibold">Status: {orderDetails.status}</p>
              </div>
             
              <Accordion className="mt-6 bg-richblack-5 ">
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="address-content" id="address-header">
                  <h1 className="font-semibold text-maroon-100">Patient details</h1>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="border border-caribbeangreen-400 grid md:grid-cols-2 sm:grid-cols-1 text-richblack-900 bg-maroon-50 rounded-md p-4">
                    <p className="font-semibold">Patient name: {orderDetails?.username }</p>
                    <p className="font-semibold">Email id: {orderDetails?.email}</p>
                    <p className="font-semibold">Contact no: {orderDetails?.phone}</p>
                    <p className="font-semibold">Age: {orderDetails?.age }</p>
                    <p className="font-semibold">Gender: {orderDetails?.gender}</p>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        )}
        <div className="border border-caribbeangreen-200 bg-richblack-50 rounded-md p-4 flex flex-col w-full mt-8 justify-evenly text-maroon-50">
          <select value={status} onChange={(e) => setStatus(e.target.value)} className="border-b border-richblack-700 p-2 rounded-md mb-4">
            <option value="completed">Completed</option>
            <option value="pending">Pending</option>
            <option value="cancelled">Cancelled</option>
          </select>
          <button onClick={handleUpdateStatus} className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-peach-300 focus:outline-none bg-maroon-50 rounded-full border border-black hover:bg-caribbeangreen-50 bg-opacity-40 hover:text-richblack-900 focus:z-10 focus:ring-4 focus:ring-caribbeangreen-600">Update Status</button>
        </div>
      </div>
      <div className="p-4 w-[80px]">
        <button className="inline-flex w-[130px] justify-center hover:underline p-3 rounded-full bg-caribbeangreen-500 text-richblack-800 bg-opacity-50 py-1 px-3 border-2 border-caribbeangreen-400 text-md font-semibold" onClick={handleBackButton}>Back</button>
      </div>
    </>
  );
}
