import React from "react";
import "../Styles/Footer.css";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { useSelector } from "react-redux";

function Footer() {
  const { token } = useSelector((state) => state.auth);
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              Dr. Harish Jaglan <span className="ft-sign">+</span>
            </p>
            <div className="items-center gap-x-4">
        {token === null ? (
          <Link to="/login">
            <button className="rounded-[16px] px-[12px] py-[8px] text-poppins items-center gap-2 text-richblack-700 text-xl flex hover:border-2 bg-caribbeangreen-400 text-white hover:bg-richblack-900 hover:border-caribbeangreen-400">
              <CgProfile />
              Admin
            </button>
          </Link>
        ) : (
         <div></div>
        )}
      </div>
            <p className="ft-description">
              Get professional physiotherapy treatment and advice from Dr. Harish Jaglan. 
              Our clinic offers personalized care for pain management, injury rehabilitation, 
              and improving mobility. Reach out to us for on-demand healthcare services at your convenience.
            </p>
          </div>

          <SubscribeNewsletter />
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Services</p>
          <ul className="ft-list-items">
            <li>
              <a href="#services">Physical Therapy</a>
            </li>
            <li>
              <a href="#services">Manual Therapy</a>
            </li>
            <li>
              <a href="#services">Post-Surgical Rehabilitation</a>
            </li>
            <li>
              <a href="#services">Sports Injury Rehabilitation</a>
            </li>
            <li>
              <a href="#services">Pain Management</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/legal"}>General Info</Link>
            </li>
            <li>
              <Link to={"/legal"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/legal"}>Terms of Services</Link>
            </li>
            <li>
              <Link to={"/legal"}>Consultations</Link>
            </li>
            <li>
              <Link to={"/legal"}>How it Works</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:drharishjaglan@gmail.com">
                drharishjaglan@gmail.com
              </a>
            </li>
            <li>
              <a href="tel:+919694158014">+91 9694158014</a>
            </li>
            <li>
              <a href="tel:+917988766281">+91 7988766281</a>
            </li>
            
          </ul>

          
        </div>

      </div>

      <div className="ft-copyright">
        <p>Made with ❤️ Urban web works ©</p>

        <ul className="ft-social-links">
          <li>
          <a
  href="https://www.instagram.com/harish_jaglan_/"
  title="Instagram"
  target="_blank"
  rel="noopener noreferrer"
>
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
<path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
</svg>
</a>

          </li>

          <li>
            <a
              href="https://www.facebook.com/harish.jaglan3375"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </li>

          {/* <li>
            <a
              href="https://x.com/harish.jaglan/"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </li> */}

          <li>
  <a
    href="https://www.youtube.com/@drjaglanphysio"
    title="Youtube"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 576 512"
    >
      <path d="M549.655 124.083C533.081 81.627 496.718 50.947 454.137 41.92 400.623 31.129 288 32 288 32s-112.623-.871-166.137 9.92c-42.581 9.027-79.077 39.707-95.551 82.163C9.371 178.917 0 235.854 0 255.985c0 20.13 9.371 77.068 26.312 131.902 16.474 42.456 52.97 73.136 95.551 82.163C175.377 480.871 288 480 288 480s112.623.871 166.137-9.92c42.581-9.027 79.077-39.707 95.551-82.163 16.941-54.834 26.312-111.772 26.312-131.902 0-20.13-9.371-77.068-26.312-131.902zM232.193 345.188v-134.5l142.857 67.25-142.857 67.25z"/>
    </svg>
  </a>
</li>

        </ul>
      </div>
    </div>
  );
}

export default Footer;
