import IconBtn from "./IconBtn"
import { Link, useNavigate } from "react-router-dom"


export default function ConfirmationModal({ modalData }) {
  const navigate = useNavigate();
  return (
    <div className="fixed inset-0 z-[1000] !mt-0 grid place-items-center overflow-auto bg-white bg-opacity-10 backdrop-blur-sm">
      <div className="w-11/12 max-w-[740px] px-20 rounded-lg border place-items-center text-center border-brown-500  bg-richblack-500 p-6">
        <p className="text-2xl font-semibold text-peach-400 ">
          {modalData?.text1}
        </p>
        <p className="mt-3 mb-5 mx-auto leading-6 text-peach-200  ">
          {modalData?.text2}
        </p>
        <div className="flex items-center justify-center mb-6 mt-10 px-10 gap-x-4">
          <IconBtn
            onclick={modalData?.btn1Handler}
            text={modalData?.btn1Text}
          />
          <button
            className="cursor-pointer rounded-md bg-peach-400  py-[8px] px-[20px] font-semibold text-richblack-700"
            onClick={modalData?.btn2Handler}
          >
            {modalData?.btn2Text}
          </button>
        </div>
        {modalData?.btn3Handler && (
          <Link
            className="cursor-pointer hover:text-primary  text-peach-300"
            onClick={navigate("/guestLogin")}
          >
            {modalData?.btn3Text}
          </Link>
        )}
      </div>
    </div>
  )
}