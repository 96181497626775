import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";

function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
        <Link to="/">
          Harish Jaglan
        </Link>
      </h1>

      <div className="legal-text-content">
        <p className="legal-title">General Info</p>
        <p className="legal-description">
          Welcome to Dr.Harish jaglan Physiotherapy Clinic, your trusted destination
          for expert physiotherapy care. Our mission is to provide accessible
          and personalized treatment to individuals seeking pain relief,
          injury rehabilitation, and improved mobility. By using our services,
          you agree to the terms outlined in our Privacy Policy and Terms of
          Service.
        </p>

        <p className="legal-title">Privacy Policy</p>
        <p className="legal-description">
          Your privacy is paramount to us. Our Privacy Policy outlines how we
          collect, use, and protect your personal and medical information. We
          ensure secure data handling, and you can trust that your information
          is treated with the utmost confidentiality.
        </p>

        <p className="legal-title">Terms of Service</p>
        <p className="legal-description">
          When using Dr.Harish jaglan Physiotherapy Clinic, you agree to our Terms of
          Service. This includes guidelines for using our platform, interacting
          with our physiotherapists, and the responsibilities of both parties.
          It's essential to understand these terms to ensure a smooth experience
          for all users.
        </p>

        <p className="legal-title">Consultations</p>
        <p className="legal-description">
          Our platform connects you with expert physiotherapists who provide
          online and in-person consultations. These consultations offer a
          convenient option for medical advice, treatment plans, and guidance.
          It's crucial to provide accurate and complete information to receive
          the best possible care.
        </p>

        <p className="legal-title">How it Works</p>
        <p className="legal-description">
          Dr.Harish jaglan Physiotherapy Clinic is designed to simplify access to
          physiotherapy care. You can choose a specialist, schedule an
          appointment, and engage in a consultation tailored to your needs. Our
          specialists offer personalized treatment plans to help you achieve
          your health goals. Please remember that emergencies require immediate
          medical attention and should be directed to your local medical
          facility.
        </p>
      </div>

      
    </div>
  );
}

export default LegalDocs;
