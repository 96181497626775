import loginImg from "../Assets/9403718.jpg"
import Template from "../Components/core/Auth/Template"

function Login() {
  return (
    <div>
    <Template
  title="Doctor/Admin Portal"
  description1="Access your dashboard to manage patient care."
  description2="Log in to provide the best care and keep track of clinic operations."
  image={loginImg} 
  formType="login"
/>


    </div>
  )
}

export default Login