import ChangeProfilePicture from "./ChangeProfilePicture"
import UpdatePassword from "./UpdatePassword"

export default function Settings() {
  return (
    <>
      <div className="mb-14 flex items-center border-b-2 flex flex-row border-b-brown-300 pb-2 font-semibold text-bistre mt-4 justify-between">
        <h1 className="text-3xl font-medium text-richblack-500">Edit Profile
      </h1>
      </div>
      {/* Change Profile Picture */}
      <ChangeProfilePicture />
      {/* Profile */}
      <UpdatePassword />
     
    </>
  )
}