import { RiEditBoxLine } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { formattedDate } from "../../../utils/dateFormatter"
import IconBtn from "../../IconBtn"
import { getUserProfileDetails } from "../../../services/operations/SettingsAPI"
import { useEffect, useState } from "react"

export default function MyProfile() {
  const { user } = useSelector((state) => state.profile)
  const navigate = useNavigate()
  const token = useSelector((state) => state.auth)

  const [profile, setProfile ] = useState("")
  
  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     const result = await getUserProfileDetails(token , user?.additionalDetails)
  //     if (result) {
  //       setProfile(result)
  //     }
  //   }
  //   fetchProfile()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // // console.log("Profile is ", profile)

  return (
    <>
      <div className="w-11/12 ">
      <h1 className="text-3xl text-richblack-500 py-4 border-b-2 font-semibold border-brown-300">
      My Profile
      </h1>
      <div className="flex mt-7 items-center justify-between rounded-md border-[3px] border-caribbeangreen-400 bg-richblack-300 sm:p-8 xs:p-4 ">
        <div className="flex md:flex-row sm:flex-col xs:flex-col space-x-3 sm:mr-64 xs:mr-74   items-center w-full">
          <img
            src={user?.image}
            alt={`profile-${user?.firstName}`}
            className="aspect-square lg:w-[78px] md:w-[78px] sm:w-[45px] xs:w-[45px] xs:mr-14  rounded-full object-cover"
          />
          <div className="space-y-1">
            <p className="lg:text-lg sm:text-md xs:text-md  font-semibold text-richblack-800">
              {user?.firstName + " " + user?.lastName}
            </p>
            <p className="text-sm text-richblack-600">{user?.email}</p>
          </div>
        </div>
        <div >
        <IconBtn
          text="Edit "
          mb={"4"}
          onclick={() => {
            navigate("/dashboard/settings")
          }}
        >
          <RiEditBoxLine />
        </IconBtn>
        </div>
      </div>
      
      <div className="my-10 flex flex-col gap-y-10 rounded-md border-[3px] border-caribbeangreen-400 bg-richblack-300 p-8 lg:px-12 md:px-12 sm:px-6 xs:px-6">
        <div className="flex w-[100%] items-center justify-between">
          <p className="text-lg font-semibold text-richblack-900">
            Personal Details
          </p>
          
        </div>
        <div className="flex lg:flex md:flex sm:flex-col xs:flex-col xs:space-y-4 max-w-[500px] justify-between">
          <div className="flex flex-col w-full gap-y-5">
            <div>
              <p className="mb-2 text-sm text-richblack-800">First Name</p>
              <p className="text-sm font-medium text-richblack-5">
                {user?.firstName} {user?.lastName}
              </p>
            </div>
            <div>
              <p className="mb-2 text-sm text-richblack-800">Email</p>
              <p className="text-sm font-medium text-richblack-5">
                {user?.email}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-5">
            <div>
              <p className="mb-2 text-sm text-richblack-800">Phone Number</p>
              <p className="text-sm font-medium text-richblack-5">
                9694158014
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}