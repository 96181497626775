import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAllAppointments } from '../../../../services/operations/routesAPI';

import AdminChart from './AdminChart';
import { Link } from 'react-router-dom';
import {MdWavingHand } from 'react-icons/md';
import OrderTable from './OrderTable';


const Admin = () => {
    // const {token} = useSelector((state)=> state.auth);
    const {user} = useSelector((state)=>state.profile);
    const [loading, setLoading] = useState(false);
    const [sliceNumber, setSliceNumber] = useState(3);
    const [orders , setOrders ] = useState([])
    const [allOrders , setAllOrders ] = useState([])
    const [todayAppointmentsCount, setTodayAppointmentsCount] = useState(0);
    const [yesterdayAppointmentsCount, setYesterdayAppointmentsCount] = useState(0);
    const [lastWeekAppointmentsCount, setLastWeekAppointmentsCount] = useState(0);

    const windowSliceHandler =( )=> {
        window.matchMedia('(min-width: 1809px)').addEventListener('change', () => setSliceNumber(3));
        window.matchMedia('(min-width: 1340px)').addEventListener('change', () => setSliceNumber(3));
        window.matchMedia('(min-width: 990px)').addEventListener('change', () =>  setSliceNumber(2));
        window.matchMedia('(min-width: 420px)').addEventListener('change', () =>  setSliceNumber(2));
    }
    windowSliceHandler();

    useEffect(() => {
        const fetchAllAppointments = async () => {
            try {
                const res = await getAllAppointments();
                setAllOrders(res);
                const sortedOrders = res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort orders by createdAt timestamp in ascending order
                const latestOrders = sortedOrders.slice(0, 6); // Get the latest 6 orders
                setOrders(latestOrders); // Update state with the latest orders
                // console.log("In fetch All Appointments ", latestOrders);
            } catch (error) {
                console.log("Could not fetch Orders:", error);
            }
        }
        
    fetchAllAppointments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

//   console.log("All orders are:", allOrders);
    
  return (
    <div className='text-richblack-900 sm:w-11/12 xs:w-full'>
      <div className='flex flex-col space-y-3 w-[500px] h-[105px]'>
        <h1 className='text-[35px] font-semibold leading-[24px] flex gap-2 text-richblack-900' >Hi {user?.firstName} <span className='text-yellow-50 text-[26px] '><MdWavingHand/> </span> </h1>
        <p className='text-[18px] text-richblack-500 leading-[22px]'>Let's start something new</p>
      </div>

      {loading ? (<div className='spinner'></div>)
      :
      <div className='relative flex flex-col'>
            <div className='relative w-full h-full md:h-[570px] sm:h-[650px] '>
            <div className='relative flex md:flex-row xs:flex-col md:space-x-2 xs:gap-y-4 sm:space-y-3'>
            <div className='relative md:w-[80%] sm:w-full max-h-[1000px] bg-bistre  rounded-lg'>
            {loading ? (
                <div className='spinner'></div>
            ) : (
                <div className='lg:w-[700px] md:w-[700px] sm:w-[400px] xs:w-[400px]'>
                    
                    <AdminChart 
                        appointments={allOrders} 
                        setTodayAppointmentsCount={setTodayAppointmentsCount}
                        setYesterdayAppointmentsCount={setYesterdayAppointmentsCount}
                        setLastWeekAppointmentsCount={setLastWeekAppointmentsCount}
                    />
                   
                </div>
            )}
                </div>
                <div className='md:w-[20%] sm:w-[100%] p-[14px] rounded-lg flex flex-col sm:space-x-4 md:space-x-0  bg-richblack-100 text-maroon-50 md:space-y-3'>
                    <p className='text-[24px] font-semibold'>Statistics</p>
                    <div className='flex md:flex-col sm:flex-row justify-between  '>
                    <div className='flex flex-col mx-auto justify-center space-y-2 md:mt-8 sm:mt-2 lg:items-center  md:items-start sm:items-center xs:items-center   '>
                        <p className='text-richblack-700  leading-8'>Today </p>
                        <p className='text-[35px] leading-[22px]'>{todayAppointmentsCount}</p>
                    </div>

                    <div className='flex flex-col space-y-2 md:mt-8 sm:mt-2 xs:mr-10 md:mr-0 lg:mr-0 lg:items-center   md:items-center  sm:items-center xs:items-center   '>
                        <p className='text-richblack-700 leading-8'>Yesterday</p>
                        <p className='text-[35px] leading-[22px]'>{yesterdayAppointmentsCount}</p>
                    </div>

                    <div className='flex flex-col space-y-2 md:mt-8 sm:mt-2 items-center  '>
                        <p className='text-richblack-700 leading-8'>week total</p>
                        <p className='text-[28px] leading-[22px] flex flex-row'>{lastWeekAppointmentsCount}</p>
                    </div>
                    </div>
                </div>
                
            </div>
            
        </div>
       
        <div className='relative flex flex-col p-[30px] xs:mt-6 lg:mt-4 sm:mt-[400px] md:mt-7 bg-richblack-200 rounded-lg '>
        
        <div className='flex justify-between w-full h-[34px]'>
            <p className='text-richblack-800  font-semibold text-[23px] leading-[22px]'>Appointment</p>
            <Link to="/dashboard/view-appointments">
                <p className='text-yellow-50 leading-5 '>View all</p>
            </Link>
        </div>
        <div className='p-4 rounded-xl'>
         <OrderTable orders={orders} /> 
         </div>
         
        </div>

        </div>
        
        }
    </div>
  )
}

export default Admin
