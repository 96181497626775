import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Legal from "./Pages/Legal";
import NotFound from "./Pages/NotFound";
import Appointment from "./Pages/Appointment";
import Login from "./Pages/Login";
import PrivateRoute from "./Components/core/Auth/PrivateRoute";
import Dashboard from "./Pages/Dashboard";
import { useSelector } from "react-redux";
import { ACCOUNT_TYPE } from "./utils/constants";
import Admin from "./Components/core/Dashboard/AdminDashboard/Admin";
import Settings from "./Components/core/Dashboard/Settings"
import ViewAllAppointments from './Components/core/Dashboard/AdminDashboard/ViewAllAppointments';
import UpdateAppointmentStatus from "./Components/core/Dashboard/AdminDashboard/UpdateAppointmentStatus";
import MyProfile from "./Components/core/Dashboard/MyProfile";
import Navbar from "./Components/Navbar";
import OfferBar from "./Components/offerBar";


function App() {
  const { user } = useSelector((state) => state.profile);
  return (
    
    <div className="App">
    <OfferBar />
    <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="*" element={<NotFound />} />

          <Route
          path="login"
          element={
              <Login />
          }
        />

<Route element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }>
          {user?.accountType === ACCOUNT_TYPE.DOCTOR && (
            <>
             <Route path="dashboard/my-profile" element={<MyProfile />} />
              <Route path="dashboard/admin" element={<Admin />} />
              <Route path="dashboard/Settings" element={<Settings />} />
              <Route path='dashboard/view-appointments' element={<ViewAllAppointments />} />
              <Route path='dashboard/update-appointment/:appointmentId' element={<UpdateAppointmentStatus />} />
              {/* <Route path="dashboard/edit-product/:productId" element={<EditProduct />} />
              <Route path="dashboard/add-discount" element={<AddDiscount />} /> */}
              
            </>
          )}
          </Route>
        </Routes>
    </div>
  );
}

export default App;
