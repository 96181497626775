import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getAllAppointments } from "../../../../services/operations/routesAPI"
import OrderTable from "./OrderTable"

export default function ViewAllAppointments() {
  const { token } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const [orders , setOrders ] = useState([])


  useEffect(() => {
    const fetchAllOrders = async () => {
        try {
          const res = await getAllAppointments(token);
          setOrders(res); 
          // console.log("In fetch All Order ", res)
        
        } catch (error) {
          console.log("Could not fetch Orders:", error);
        }
        }
    fetchAllOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="mb-14 flex items-center border-b-2 flex flex-row border-b-caribbeangreen-300 pb-2 font-semibold text-bistre mt-4 justify-between">
        <h1 className="text-3xl font-medium text-richblack-900">All Appointments</h1>
        
      </div>
      {orders && <OrderTable orders={orders} />}
    </div>
  )
}