import React, { useState  } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CgProfile } from "react-icons/cg"; // Ensure this import is correct
import logo from "../Assets/HarishJaglan-removebg-preview.png";
import ProfileDropdown from "../Components/core/Auth/ProfileDropDown"; // Ensure this component exists and is correctly imported
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../services/operations/authAPI";
import { VscSignOut } from "react-icons/vsc";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [open, setOpen] = useState(false)
  const { token } = useSelector((state) => state.auth);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openNav = () => {
    setNav(!nav);
  };

  const handleChatBtnClick = () => {
    if (!isButtonDisabled) {
      toast.info("Experiencing high traffic, Please wait a moment.", {
        position: toast.POSITION.TOP_CENTER,
        onOpen: () => setIsButtonDisabled(true),
        onClose: () => setIsButtonDisabled(false),
      });
    }
  };

  return (
    <div className="navbar-section">
      <div className="navbar-content">
    <Link to="/" className="navbar-title">
      <img src={logo} alt="Logo" className="logo-image" loading="lazy" />
      <h1 className="navbar-heading">Dr. Harish Jaglan</h1>
    </Link>
  </div>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/" className="navbar-links">
            Home
          </Link>
        </li>
        <li>
          <a href="#services" className="navbar-links">
            Services
          </a>
        </li>
        <li>
          <a href="#about" className="navbar-links">
            About
          </a>
        </li>
        <li>
          <a href="#reviews" className="navbar-links">
            Reviews
          </a>
        </li>
        <li>
          <a href="#doctors" className="navbar-links">
            Doctors
          </a>
        </li>
      </ul>

      {/* Login / Signup / Dashboard */}

      {token !== null && (
          <div className="flex space-x-2 h-10 hidden items-center gap-x-4 md:flex   ">
          <ProfileDropdown  />
          <div
            onClick={() => {
              dispatch(logout(navigate))
              setOpen(false)
            }}
            className="flex w-full items-center cursor-pointer gap-x-1 py-[10px] px-[12px] text-sm text-maroon-100  hover:underline"
          >
          <VscSignOut />
          Logout
          </div>
          </div> )}

      {/* Mobile */}
      <button className="relative md:hidden sm:block xs:block">
        <div className="flex items-center gap-x-3">
          {token !== null && <ProfileDropdown />}
        </div>
      </button>

      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/">
              Home
            </Link>
          </li>
          <li>
            <a onClick={openNav} href="#services">
              Services
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#about">
              About
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#reviews">
              Reviews
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#doctors">
              Doctors
            </a>
          </li>
          <li>
            <a onClick={openNav} href="#contact">
              Contact
            </a>
          </li>
        </ul>
        <div className="flex items-center gap-x-3">
          {token !== null ? (
            
            <div
            onClick={() => {
              dispatch(logout(navigate))
              setOpen(false)
            }}
            className="flex w-full items-center cursor-pointer gap-x-1 py-[10px] px-[12px] text-sm text-maroon-100  hover:underline"
          >
          <VscSignOut />
          Logout
          </div>
          ) : (<div> </div>)}
        </div>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon icon={faBars} onClick={openNav} className="hamb-icon" />
      </div>
    </div>
  );
}

export default Navbar;
