import { ACCOUNT_TYPE } from "../../../utils/constants";


export const sidebarLinks = [
  {
    id: 1,
    name: "My Profile",
    path: "/dashboard/my-profile",
    icon: "VscAccount",
  },
  {
    id: 2,
    name: "Dashboard",
    path: "/dashboard/admin",
    type: ACCOUNT_TYPE.DOCTOR,
    icon: "VscDashboard",
  },
  {
    id: 3,
    name: "View Appointments",
    path: "/dashboard/view-appointments",
    type: ACCOUNT_TYPE.DOCTOR,
    icon: "VscArchive",
  },
  
  
 
];
