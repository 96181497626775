import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function InformationCard(props) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/appointment");
  };

  return (
    <div className="info-cards" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <div className="info-card-icon">
        <FontAwesomeIcon className="info-fa-icon" icon={props.icon} />
      </div>
      <div className="info-card-content">
        <img src={props.imageUrl} alt={props.title} className="info-card-image" />
        <p className="info-card-title">{props.title}</p>
        <p className="info-card-description">{props.description}</p>
      </div>
    </div>
  );
}

export default InformationCard;
