import React, { useState, useEffect } from 'react';
import { Chart, registerables } from "chart.js";
import { Pie } from "react-chartjs-2";

Chart.register(...registerables);

const AdminChart = ({ appointments, setTodayAppointmentsCount, setYesterdayAppointmentsCount, setLastWeekAppointmentsCount }) => {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const aggregateChartData = () => {
            const today = new Date().toLocaleDateString();
            const yesterday = new Date(Date.now() - 86400000).toLocaleDateString();
            const lastWeek = new Date(Date.now() - 7 * 86400000).toLocaleDateString();
            
            const todayAppointments = appointments.filter(appointment => new Date(appointment.createdAt).toLocaleDateString() === today);
            const yesterdayAppointments = appointments.filter(appointment => new Date(appointment.createdAt).toLocaleDateString() === yesterday);
            const lastWeekAppointments = appointments.filter(appointment => new Date(appointment.createdAt) >= new Date(lastWeek));
             
            setTodayAppointmentsCount(todayAppointments.length);
            setYesterdayAppointmentsCount(yesterdayAppointments.length);
            setLastWeekAppointmentsCount(lastWeekAppointments.length);

            const chartData = [
                { category: 'Today', count: todayAppointments.length },
                { category: 'Yesterday', count: yesterdayAppointments.length },
                { category: 'Last Week', count: lastWeekAppointments.length },
            ];
            
            return chartData;
        };

        setChartData(aggregateChartData());
    }, [appointments, setTodayAppointmentsCount, setYesterdayAppointmentsCount, setLastWeekAppointmentsCount]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Appointments Overview',
            },
        },
    };

    const chartDataForAppointments = {
        labels: chartData.map(data => data.category),
        datasets: [
            {
                label: 'Number of Appointments',
                data: chartData.map(data => data.count),
                backgroundColor: [
                    'rgba(231, 18, 7, 0.8)', // Today
                    'rgba(239, 237, 83, 0.8)', // Yesterday
                    'rgba(83, 231, 239, 0.8)', // Last Week
                ],
            },
        ],
    };

    return (
        <div className='p-7 max-h-[850px] bg-caribbeangreen-25 rounded-2xl relative'>
            <p className='text-[20px] text-maroon-50 font-semibold'>Visualize</p>
            <div className='md:w-[400px] xs:w-[250px] flex lg:flex-row md:flex-row sm:flex-col xs:flex-col gap-10 sm:w-[300px] mx-auto mt-10'>
                <div className='flex flex-col items-center justify-center gap-4'>
                    <h1 className='text-lg font-mono font-semibold text-maroon-50'>Appointments</h1>
                    <Pie 
                        data={chartDataForAppointments} // Pass chart data for appointments
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminChart;
