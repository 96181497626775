import React, { useState, useEffect } from "react";
import { HiOutlinePhoneArrowDownLeft } from "react-icons/hi2";
import { LuMailSearch } from "react-icons/lu";
import { IoLocation } from "react-icons/io5";

export default function OfferBar() {
  


  return (
    <div className="w-full flex md:h-[2.4rem] sm:h-[4.5rem] items-center justify-center h- bg-caribbeangreen-300">
      <div className="w-11/12 mx-auto justify-between flex lg:flex-row md:flex-row sm:flex-col xs:flex-col w-11/12 p-2 text-white max-w-maxContent items-center mx-auto">
      <div className="flex flex-row md:flex-row sm:flex-col xs:flex-col">
        <div className=" relative px-12 flex flex-row items-baseline align-baseline font-intern">
        <HiOutlinePhoneArrowDownLeft className="absolute left-6  top-[6px]" />
        <p className="font-semibold font-intern text-xl">+91 9694158014</p>
        </div>
        <div className=" relative px-12 flex flex-row items-baseline align-baseline font-intern">
        <IoLocation className="absolute left-6  top-[6px]" />
        <p className="font-semibold font-intern text-xl">Sector 22B, Chandigarh .</p>
        </div>

</div>
        <div className=" relative px-12 flex flex-row items-baseline align-baseline font-intern">
        <LuMailSearch className="absolute left-6  top-[6px]" />
        <p className="font-semibold font-intern text-xl">drharishjaglan@gmail.com</p>
        </div>
      </div>
    </div>
  );
}
