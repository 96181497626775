import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import TablePagination from '@mui/material/TablePagination';
import * as XLSX from 'xlsx'; 

const OrderTable = ({ orders }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };

  const handleSearch = (event) => {
      setSearchQuery(event.target.value);
  };

  const filteredOrders = orders.filter(order => {
      const orderDate = new Date(order?.createdAt); 
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7);
      
      // Check status filter
      const statusMatch = statusFilter === 'all' || order.status === statusFilter;

      // Check search query
      const searchMatch = !searchQuery || order._id.toLowerCase().includes(searchQuery.toLowerCase());
      
      // Apply date filter
      if (filter === 'today') {
          return orderDate.toDateString() === today.toDateString() && statusMatch && searchMatch;
      } else if (filter === 'yesterday') {
          return orderDate.toDateString() === yesterday.toDateString() && statusMatch && searchMatch;
      } else if (filter === 'lastWeek') {
          return orderDate >= lastWeek && orderDate <= today && statusMatch && searchMatch;
      } else {
          return statusMatch && searchMatch; // Default case
      }
  });

  const sortedOrders = filteredOrders.sort((a, b) => new Date(b.appointmentDate + ' ' + b.appointmentTime) - new Date(a.appointmentDate + ' ' + a.appointmentTime));

  const indexOfLastOrder = (page + 1) * rowsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - rowsPerPage;
  const currentOrders = sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Function to handle downloading data as XLSX
  const handleDownload = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'orders'; // Change file name as needed

    // Prepare data for download
    const ordersData = sortedOrders.map(order => ({
      'Order ID': order._id,
      'Date of Order': formatDate(order.createdAt),
      'Order Status': order.orderLocation,
      'Payment Status': order.paymentStatus
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(ordersData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create blob and download
    const data = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName + fileExtension);
    document.body.appendChild(link);
    link.click();
  };

  return (
      <>
          <div className="flex flex-row md:flex-row sm:flex-col xs:flex-col gap-4 justify-between mb-4">
              <div>
                  <select 
                      value={filter} 
                      onChange={(e) => setFilter(e.target.value)} 
                      className="px-3 py-1 border text-caribbeangreen-700 bg-richblack-5 border-caribbeangreen-400 bg-peach rounded-md focus:outline-none"
                  >
                      <option className='bg-peach text-caribbeangreen-600' value="all">All Dates</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="lastWeek">Last Week</option>
                  </select>
              </div>
              <div>
                  <select 
                      value={statusFilter} 
                      onChange={(e) => setStatusFilter(e.target.value)} 
                      className="px-3 py-1 border text-caribbeangreen-700 bg-richblack-5 border-caribbeangreen-400 bg-peach  rounded-md focus:outline-none"
                  >
                      <option value="all">All</option>
                      <option value="completed">Confirmed</option>
                      <option value="cancelled">Cancelled</option>
                  </select>
              </div>
              <div>
                  <input 
                      type="text" 
                      value={searchQuery} 
                      onChange={handleSearch} 
                      placeholder="Search orderId" 
                      className="px-3 py-1 border text-caribbeangreen-700 bg-richblack-5 border-caribbeangreen-400 bg-peach  rounded-md focus:outline-none"
                  />
              </div>
          </div>
          <button onClick={handleDownload} className="px-3 py-1 border text-caribbeangreen-700 bg-richblack-5 border-caribbeangreen-400 bg-peach rounded-md focus:outline-none">
          Download Data
        </button>
          <TablePagination
              component="div"
              count={sortedOrders.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <div className="rounded-lg border border-caribbeangreen-600 bg-richblack-50 w-full px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1">
              <div className="max-w-full overflow-x-auto">
                  <table className="w-full table-auto">
                      <thead>
                          <tr className="bg-caribbeangreen-300 w-[120%] px-6 rounded-full text-left dark:bg-meta-4">
                              <th className="min-w-[220px] py-4 px-14 font-medium text-black dark:text-white xl:pl-11">
                                  Name
                              </th>
                              <th className="min-w-[220px] py-4 px-14 font-medium text-black dark:text-white xl:pl-11">
                              Time
                              </th>
                              <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                  Date
                              </th>
                              <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                Status
                              </th>
                              <th className="py-4 font-medium text-black dark:text-white px-12 xl:pl-11">
                                  Actions
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          {currentOrders.length > 0 ? (
                              currentOrders.map((item, index) => (
                                  <tr key={index}>
                            
                                      <td className="border-b border-caribbeangreen-400 px-4">
                                          <h5 className="font-medium text-black dark:text-white">
                                              {item?.username}
                                          </h5>
                                          <p className='text-richblack-500'>{item?._id}</p>
                            
                                      </td>
                                      <td className="border-b border-caribbeangreen-400  px-14 ">
                                          <h5 className="font-sm text-black dark:text-white">
                                              {item?.appointmentTime}
                                          </h5>
                            
                                      </td>
                                      <td className="border-b border-caribbeangreen-400 ">
                                          <p className="text-black dark:text-white">
                                              {formatDate(item?.appointmentDate)}
                                          </p>
                                      </td>
                                      <td className="border-b border-caribbeangreen-400 ">
                                          <p className="text-black dark:text-white">
                                              {item?.status}
                                          </p>
                                      </td>
                                      <td className="border-b border-caribbeangreen-400 py-5 px-4 dark:border-strokedark">
                                          <p
                                              className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                                                  item.paymentStatus === 'paid'
                                                      ? 'bg-success text-success'
                                                      : item.paymentStatus === 'pending'
                                                          ? 'bg-danger text-danger'
                                                          : 'bg-warning text-warning'
                                                  }`}
                                          >
                                              {item?.paymentStatus}
                                          </p>
                                      </td>
                                      <td className="border-b border-brown-400 py-5 mr-10 ">

                                              <button
                                                  onClick={() => {
                                                      navigate(`/dashboard/update-appointment/${item._id}`)
                                                  }}
                                                  title="Edit"
                                                  className="-px-20 -ml-20 transition-all duration-200 hover:scale-110 hover:text-caribbeangreen-300"
                                              >
                                                  <FiEdit2 size={20} />
                                              </button>
                                         
                                      </td>
                                  </tr>
                              ))
                          ) : (
                              <tr>
                                  <td colSpan="4">No Orders found</td>
                              </tr>
                          )}
                      </tbody>
                  </table>
              </div>
          </div>
      </>
  );
};

export default OrderTable;
