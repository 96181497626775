import { toast } from "react-hot-toast"

import { updateCompletedLectures } from "../../slices/viewCourseSlice"
// import { setLoading } from "../../slices/profileSlice";
import { apiConnector } from "../apiconnector"
import { appointmentEndpoints } from "../apis"

const {
  GET_ALL_APPOINTMENTS_API,
  APPOINTMENT_DETAILS_API,
  UPDATE_APPOINTMENT_STATUS ,
  CREATE_APPOINTMENT_API
  
} = appointmentEndpoints


export const createAppointment = async (token, appointmentData) => {
  const toastId = toast.loading("Loading...");
  let result = null;
  try {
    // console.log("In Appointment ", appointmentData)
    const response = await apiConnector("POST", CREATE_APPOINTMENT_API, appointmentData, {
      Authorization: `Bearer ${token}`,
    });
    if (!response?.data?.success) {
      throw new Error("Could Not Create Appointment");
    }
    toast.success("Appointment Created Successfully");
    result = response?.data?.data;
  } catch (error) {
    console.log("CREATE_APPOINTMENT_API API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};

export const getAllAppointments = async () => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    // console.log("In fetch Appointment", token); // Log the token
    const response = await apiConnector(
      "GET",
      GET_ALL_APPOINTMENTS_API
    )
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch appointments");
    }
    result = response?.data?.data;
    // console.log("Appointment are ", result);
  } catch (error) {
    console.log("APPOINTMENTS API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};


export const fetchAppointmentDetails = async (token , appointmentId ) => {
  const toastId = toast.loading("Loading...")
  //   dispatch(setLoading(true));
  let result = null
  try {
    // console.log("In fetch Appointment :", token , appointmentId)
    const response = await apiConnector("POST", APPOINTMENT_DETAILS_API, {
      appointmentId,
    },
    {
      Authorization: `Bearer ${token}`,
    })
    // console.log("APPOINTMENT_DETAILS_API API RESPONSE............", response)

    if (!response.data.success) {
      throw new Error(response.data.message)
    }
    result = response.data
  } catch (error) {
    console.log("APPOINTMENT_DETAILS_API API ERROR............", error)
    result = error.response.data
    // toast.error(error.response.data.message);
  }
  toast.dismiss(toastId)
  //   dispatch(setLoading(false));
  return result
};


export const updateAppointmentStatus= async (token , data) => {
  let result = null
  const toastId = toast.loading("Loading...")
  // console.log("data to update", data)
  try {
    const response = await apiConnector("PUT", UPDATE_APPOINTMENT_STATUS, data, {
      Authorization: `Bearer ${token}`,
    })
    // console.log("Update APPOINTMENT API RESPONSE............", response)
    if (!response?.data?.success) {
      throw new Error("Could Not Update Appointment Details")
    }
    toast.success("Appointment Details Updated Successfully")
    result = response?.data?.data
  } catch (error) {
    console.log("UPDATE Appointment API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
  return result
}


