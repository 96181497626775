import React from "react";
import "../Styles/Doctors.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck , faPhone } from "@fortawesome/free-solid-svg-icons";

function Doctors() {
  const handleCall = () => {
    window.location.href = "tel:+919694158014"; 
  };

  return (
    <div className="doctor-section" id="doctors">
      <div className="map-container">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3429.433397957564!2d76.77518407466164!3d30.73432538555731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fede8abab623d%3A0x8e02d3bae9cf678b!2sDr.%20Harish%20Jaglan%20Physiotherapy%20Clinic%20%26%20Best%20Physiotherapist%20in%20Chandigarh%2CHome%20Physiotherapy%20Services%2CPhysiotherapist%20.!5e0!3m2!1sen!2sin!4v1721310329144!5m2!1sen!2sin" 
          width="600" 
          height="450" 
          style={{border:0, width: '100%', height: '450px'}} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="dt-title-content flex lg:flex-row lg:-space-y-[0.8rem]  md:space-y-[0.1rem] sm:space-y-[3rem]  xs:space-y-[3rem]  md:flex-row sm:flex-col xs:flex-col justify-around p-8 mt-[2rem]  ">
        <h3 className="dt-title">
          <span>Need any help? Call us at </span>
        </h3>

        <div>
          <button
            className="text-appointment-btn bg-caribbeangreen-400 text-white "
            type="button"
            onClick={handleCall}
          >
            <FontAwesomeIcon icon={faPhone} /> Call Now
           
          </button>
        </div>
      </div>
    </div>
  );
}

export default Doctors;
