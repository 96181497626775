import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/AppointmentForm.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { createAppointment, getAllAppointments } from "../services/operations/routesAPI"; // Adjust the import path as needed
import { useSelector } from "react-redux"; // Assuming you are using redux for token management
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import moment from 'moment';

function AppointmentForm() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const token = useSelector((state) => state.auth.token);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    gender: "",
    age: "",
    weight: "",
    serviceTitle: "",
  });

  // Initialize appointmentDate as null
  const [appointmentDate, setAppointmentDate] = useState(null); 
  const [appointmentTime, setAppointmentTime] = useState("");
  
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [slots, setSlots] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");

  let inTime = "9:00 AM";
  let outTime = "08:00 PM";

  const fetchAppointments = async () => {
    try {
      const appointments = await getAllAppointments();
      if (appointmentDate) {
        // Filter appointments by the selected date
        const formattedDate = appointmentDate.format('YYYY-MM-DD');
        const bookedSlots = appointments
          .filter(appointment => dayjs(appointment.appointmentDate).format('YYYY-MM-DD') === formattedDate)
          .map(appointment => moment(appointment.appointmentTime, 'HH:mm').format('hh:mm a'));
        filterAvailableSlots(bookedSlots);
      }
    } catch (error) {
      toast.error("Failed to fetch appointments");
    }
  };

  useEffect(() => {
    fetchAppointments(); // Fetch appointments when component mounts and when appointmentDate changes
  }, [appointmentDate]);

  function intervals(startString, endString) {
    var start = moment(startString, 'hh:mm a');
    var end = moment(endString, 'hh:mm a');
    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var current = moment(start);

    const slotsArray = [];

    while (current <= end) {
      if (!slotsArray.includes(current.format('hh:mm a'))) {
        slotsArray.push(current.format('hh:mm a'));
      }
      current.add(30, 'minutes');
    }

    setSlots(slotsArray);
  }

  useEffect(() => {
    intervals(inTime, outTime);
  }, []);

  const filterAvailableSlots = (bookedSlots) => {
    const filteredSlots = slots.filter(slot => !bookedSlots.includes(slot));
    setAvailableSlots(filteredSlots);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setAppointmentDate(date ? date.startOf('day') : null); // Handle date selection
  };

  const handleSlotSelect = (slot) => {
    if (isSlotDisabled(slot)) {
      toast.error("Cannot select a past time slot");
      return;
    }
    setSelectedSlot(slot);
    setAppointmentTime(slot);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form inputs
    const errors = {};
    if (!formData.username.trim()) {
      errors.username = "Username is required";
    }
    if (!appointmentDate) {
      errors.appointmentDate = "Appointment date is required";
    }
    if (!appointmentTime) {
      errors.appointmentTime = "Appointment time is required";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const formattedDate = appointmentDate.toDate(); // Convert dayjs date to JavaScript Date object
      const formattedTime = moment(selectedSlot, 'hh:mm a').format('HH:mm'); // Convert selectedSlot to HH:mm format

      const result = await createAppointment(token, {
        ...formData,
        appointmentDate: formattedDate,
        appointmentTime: formattedTime,
      });

      if (result) {
        // Reset form fields
        setFormData({
          username: "",
          email: "",
          phone: "",
          gender: "",
          age: "",
          weight: "",
          serviceTitle: "",
        });
        setAppointmentDate(null); // Reset date picker to no date
        setAppointmentTime(""); // Reset selected slot
        setSelectedSlot(""); // Reset selected slot
        setFormErrors({});
        toast.success("Appointment created successfully!", {
          onOpen: () => setIsSubmitted(true),
          onClose: () => setIsSubmitted(false),
        });
      }
    } catch (error) {
      toast.error("Failed to create appointment");
    }
  };

  const categorizeSlots = (slots) => {
    const morningSlots = [];
    const afternoonSlots = [];
    const eveningSlots = [];

    slots.forEach(slot => {
      const time = moment(slot, 'hh:mm a');
      if (time.isBefore(moment('12:00 PM', 'hh:mm a'))) {
        morningSlots.push(slot);
      } else if (time.isBefore(moment('05:00 PM', 'hh:mm a'))) {
        afternoonSlots.push(slot);
      } else {
        eveningSlots.push(slot);
      }
    });

    return { morningSlots, afternoonSlots, eveningSlots };
  };

  const isSlotDisabled = (slot) => {
    const currentDateTime = moment();
    const slotDateTime = moment(appointmentDate ? appointmentDate.format('YYYY-MM-DD') + ' ' + slot : '', 'YYYY-MM-DD hh:mm a');
    return slotDateTime.isBefore(currentDateTime);
  };

  const { morningSlots, afternoonSlots, eveningSlots } = categorizeSlots(availableSlots);

  return (
    <div className="appointment-form-section">
      <h1 className="legal-siteTitle">
        <Link to="/">
          Dr. Harish Jaglan 
        </Link>
      </h1>

      <div className="form-container">
        <h2 className="form-title">
          <span>Book Appointment Online</span>
        </h2>

        <form className="form-content" onSubmit={handleSubmit}>
          <label>
          <div className="flex flex-row">
          <div className="text-[#ff4d4d] text-4px ">*</div>
            Name:
            </div>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
            {formErrors.username && <p className="error-message">{formErrors.username}</p>}
          </label>
          <br />
          <label>
          <div className="flex flex-row">
          <div className="text-[#ff4d4d] text-4px ">*</div>
            Email:
            </div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {formErrors.email && <p className="error-message">{formErrors.email}</p>}
          </label>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="calendar-container">
              <StaticDatePicker
                label="Appointment Date"
                value={appointmentDate}
                onChange={handleDateChange}
                minDate={dayjs().startOf('day')}
                renderInput={(params) => <TextField {...params} />}
                required
              />
            </div>
          </LocalizationProvider>
          {formErrors.appointmentDate && <p className="error-message">{formErrors.appointmentDate}</p>}

          <div className='slots-container'>
            {morningSlots.length > 0 && (
              <div className='slots-section'>
                <h3 className="noon">Morning</h3>
                <div className='slots grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 xs:grid-cols-3'>
                  {morningSlots.map((time, index) => (
                    <div
                      key={index}
                      className={`slot-item ${selectedSlot === time ? 'selected' : ''} ${isSlotDisabled(time) ? 'disabled' : ''}`}
                      onClick={() => handleSlotSelect(time)}
                    >
                      <p>{time}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {afternoonSlots.length > 0 && (
              <div className='slots-section'>
                <h3 className="noon">Afternoon</h3>
                <div className='slots grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 xs:grid-cols-3'>
                  {afternoonSlots.map((time, index) => (
                    <div
                      key={index}
                      className={`slot-item ${selectedSlot === time ? 'selected' : ''} ${isSlotDisabled(time) ? 'disabled' : ''}`}
                      onClick={() => handleSlotSelect(time)}
                    >
                      <p>{time}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {eveningSlots.length > 0 && (
              <div className='slots-section'>
                <h3 className="noon" >Evening</h3>
                <div className='slots grid lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 xs:grid-cols-3'>
                  {eveningSlots.map((time, index) => (
                    <div
                      key={index}
                      className={`slot-item ${selectedSlot === time ? 'selected' : ''} ${isSlotDisabled(time) ? 'disabled' : ''}`}
                      onClick={() => handleSlotSelect(time)}
                    >
                      <p>{time}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {formErrors.appointmentTime && <p className="error-message">{formErrors.appointmentTime}</p>}
          <br />
          <label>
          <div className="flex flex-row">
          <div className="text-[#ff4d4d] text-4px ">*</div>
            Phone:
            </div>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            {formErrors.phone && <p className="error-message">{formErrors.phone}</p>}
          </label>
          <br />
          <label>
  Gender:
  <select
    className="dropdown"
    name="gender"
    value={formData.gender}
    onChange={handleChange}
  >
    <option value="">Select</option>
    <option value="male">Male</option>
    <option value="female">Female</option>
    <option value="other">Other</option>
  </select>
</label>
<br />
<label>
  Age:
  <input
    type="number"
    name="age"
    value={formData.age}
    onChange={handleChange}
  />
</label>
<br />
<label>
  Service Title:
  <input
    type="text"
    name="serviceTitle"
    value={formData.serviceTitle}
    onChange={handleChange}
  />
</label>
          <br />
          <button type="submit" className="text-appointment-btn bg-caribbeangreen-400 text-white">
            Confirm Appointment
          </button>
        </form>
      </div>

      <ToastContainer autoClose={5000} limit={1} closeButton={false} />
    </div>
  );
}

export default AppointmentForm;
