import React from "react";
import InformationCard from "./InformationCard";
import { faRunning, faUserMd, faProcedures, faHandsHelping, faWeight, faHeartbeat, faMugHot , faBrain, faLungs, faBurn, faFemale, faChild, faBone, faArrowsAltH, faSnowflake, faHandHoldingHeart, faGolfBall, faChair, faStethoscope, faHeadSideCough, faSadCry } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";
import image1 from "../Assets/asian-doctor-physiotherapist-examining-massaging-and-treatment-knee-and-leg-of-senior-patient-in-orthopedist-medical-clinic-nurse-hospital-free-photo.jpg";
import image2 from "../Assets/doctor-checking-patient-elbow.jpg";
import image3 from "../Assets/doctor-checking-patient-s-knees.jpg";
import image4 from "../Assets/electro-stimulation-in-physical-therapy-to-a-young-woman-photo.jpg";
import image5 from "../Assets/Chiropractic1.jpg";
import image6 from "../Assets/medical-massage-at-the-leg-in-a-physiotherapy-center-photo.jpg";
import image7 from "../Assets/the-doctor-examines-a-patient-with-back-pain-inflammation-of-the-back-free-photo.jpg";
import image8 from "../Assets/taping.jpg";
import image9 from "../Assets/neede.jpg";
import cupping from "../Assets/istockphoto-1283338558-612x612.jpg";
import stroke from "../Assets/stroke.jpeg";
import ligamentSprains from "../Assets/liga.jpg";
import frozenShoulder from "../Assets/frozen-shoulder.jpeg";
import carpalTunnel from "../Assets/carpal-tunnel.jpeg";
import golferElbow from "../Assets/golfer-elbow.jpeg";
import posturalPain from "../Assets/postural-pain.jpg";
import kneeOsteoarthritis from "../Assets/knee-osteoarthritis.jpg";
import cervicalPain from "../Assets/cervical-pain.jpeg";
import sciatica from "../Assets/sciatica.png";
import Iastm from "../Assets/IATM.jpeg"
import Paediatric from "../Assets/Paediatric.jpeg"
import Cardio from "../Assets/Cardio.jpeg"
import Orthopaedic from "../Assets/Orthopaedic.jpg"
import Neurorehabilitation from "../Assets/Neurorehabilitation.jpg"

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>Our Services</span>
        </h3>
        <p className="info-description">
          At our physiotherapy clinic, we provide personalized care designed to
          help you recover, improve, and maintain your physical health. Our
          experienced team is dedicated to offering a range of services tailored
          to meet your unique needs.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Physical Therapy"
          description="Our physical therapy services aim to restore your movement and
            functionality. Whether you're recovering from an injury, surgery, or
            dealing with chronic pain, our customized therapy plans are designed
            to help you achieve optimal health and mobility."
          icon={faRunning}
          imageUrl={image1} 
        />

      
        <InformationCard
          title="Post-Surgical Rehabilitation"
          description="We provide comprehensive post-surgical rehabilitation programs
            to ensure a smooth and efficient recovery process. Our team will
            work with you to regain strength, improve mobility, and restore
            function after surgery."
          icon={faProcedures}
          imageUrl={image3}
        />

       <InformationCard
          title="Kinesio Taping Therapy"
          description="Kinesio taping provides support and stability to your muscles and joints without restricting your range of motion, aiding in the recovery process."
          icon={faBurn}
          imageUrl={image8}
        />

        <InformationCard
          title="Dry Needling Therapy"
          description="Dry needling involves the insertion of fine needles into trigger points to relieve pain and improve muscle function, enhancing the healing process."
          icon={faFemale}
          imageUrl={image9}
        />

        <InformationCard
          title="Graston Therapy (IASTM)"
          description="Graston therapy uses specialized instruments to detect and treat areas of soft tissue fibrosis or chronic inflammation, improving mobility and function."
          icon={faProcedures}
          imageUrl={Iastm}
        />

        <InformationCard
          title="Geriatric Physiotherapy"
          description="Geriatric physiotherapy focuses on improving the mobility, balance, and overall physical health of elderly patients, enhancing their quality of life."
          icon={faHeartbeat}
          imageUrl={image2}
        />

        <InformationCard
          title="Paediatric Physiotherapy"
          description="Specialized rehabilitation services for children, addressing developmental delays, injuries, or conditions affecting their physical function."
          icon={faChild}
          imageUrl={Paediatric}
        />

        <InformationCard
          title="Cardio-Respiratory Physiotherapy"
          description="Cardio-respiratory physiotherapy focuses on treating patients with heart and lung conditions, improving their respiratory and cardiovascular health."
          icon={faLungs}
          imageUrl={Cardio}
        />

        <InformationCard
          title="Musculo-skeletal Physiotherapy / Orthopaedic Physiotherapy"
          description="Musculo-skeletal physiotherapy addresses conditions related to muscles, bones, and joints, enhancing physical function and reducing pain."
          icon={faRunning}
          imageUrl={Orthopaedic}
        />
       
        <InformationCard
          title="Chiropractic Care"
          description="Our chiropractic services focus on spinal manipulation and
            adjustments to relieve pain and improve function. We aim to restore
            spinal health and enhance your overall well-being."
          icon={faWeight}
          imageUrl={image5}
        />

        <InformationCard
          title="Osteopathy"
          description="Osteopathic treatments involve manipulating and stretching muscles
            and joints, emphasizing holistic approaches to health and wellness."
          icon={faHeartbeat}
          imageUrl={image6}
        />

        <InformationCard
          title="Neurorehabilitation"
          description="Neurorehabilitation focuses on rehabilitating patients with nervous
            system disorders or injuries, aiming to improve their quality of life
            and functional independence."
          icon={faBrain}
          imageUrl={Neurorehabilitation}
        />

        <InformationCard
          title="Cupping Therapy"
          description="Traditional therapy involving suction cups to promote blood flow, relieve muscle tension, and improve overall well-being."
          icon={faMugHot} 
          imageUrl={cupping}
        />

        <InformationCard
          title="Stroke Rehabilitation"
          description="Comprehensive rehabilitation services for stroke patients, focusing on improving mobility, strength, and overall function."
          icon={faBrain}
          imageUrl={stroke}
        />

        <InformationCard
          title="Ligament Sprains"
          description="Targeted treatment for ligament sprains to reduce pain, enhance healing, and restore joint stability."
          icon={faArrowsAltH}
          imageUrl={ligamentSprains}
        />

        <InformationCard
          title="Frozen Shoulder"
          description="Specialized therapy for frozen shoulder to relieve pain, improve range of motion, and restore shoulder function."
          icon={faSnowflake}
          imageUrl={frozenShoulder}
        />

        <InformationCard
          title="Carpal Tunnel Syndrome"
          description="Therapy focused on alleviating symptoms of carpal tunnel syndrome, enhancing hand and wrist function."
          icon={faHandHoldingHeart}
          imageUrl={carpalTunnel}
        />

        <InformationCard
          title="Golfer's Elbow"
          description="Treatment for golfer's elbow to reduce pain and inflammation, restore function, and prevent recurrence."
          icon={faGolfBall}
          imageUrl={golferElbow}
        />

        <InformationCard
          title="Postural Pain"
          description="Therapeutic interventions to address postural pain, improve posture, and enhance overall comfort."
          icon={faChair}
          imageUrl={posturalPain}
        />

        <InformationCard
          title="Knee Osteoarthritis"
          description="Comprehensive management and rehabilitation for knee osteoarthritis to alleviate pain and improve function."
          icon={faBone}
          imageUrl={kneeOsteoarthritis}
        />

        <InformationCard
          title="Cervical Pain"
          description="Focused therapy for cervical pain to relieve discomfort, enhance mobility, and improve neck function."
          icon={faHeadSideCough}
          imageUrl={cervicalPain}
        />

        <InformationCard
          title="Sciatica"
          description="Specialized treatment for sciatica to reduce pain, improve mobility, and enhance quality of life."
          icon={faSadCry}
          imageUrl={sciatica}
        />
      </div>
    </div>
  );
}

export default Info;
