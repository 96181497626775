import React, { useEffect, useState } from "react";
import Doctor from "../Assets/physiotherapy-concept-illustration.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/Hero.css";
import Backdrop from "../Assets/vecteezy_patient-at-physiotherapy-doing-physical-therapy-exercises_2400577.mp4";

function Hero() {

  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const handleCall = () => {
    window.location.href = 'tel:+91 9694158014'; 
  };

  const handleWhatsApp = () => {
    window.location.href = 'https://api.whatsapp.com/message/Q3VDNF7MTYABF1?autoload=1&app_absent=0'; 
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="video-background">
        <video autoPlay loop muted className="video-content">
          <source src={Backdrop} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">❤️ Your Well-being is Our Priority</p>
          <h2 className="text-title">
            Dr.Harish Jaglan Physiotherapy & Chiropractic Clinic
          </h2>
          <p className="text-description">
            Get personalized physiotherapy care, expert consultations, and customized treatment plans to help you recover and stay healthy. Book an appointment today at Dr. Harish Jaglan Physiotherapy & Chiropractic Clinic, the best physiotherapist in Chandigarh, offering home physiotherapy services and expert physiotherapy care.
          </p>

          <button
            className="text-appointment-btn bg-caribbeangreen-400 text-white"
            type="button"
            onClick={handleBookAppointmentClick}
          >
            <FontAwesomeIcon icon={faCalendarCheck} /> Book Appointment
          </button>
          <div className="text-stats">
            <div className="text-stats-container">
              <p>13999+</p>
              <p>Receive Patients</p>
            </div>

            <div className="text-stats-container">
              <p>5+</p>
              <p>Years of Experience</p>
            </div>
          </div>
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>


      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
      <div
        onClick={handleWhatsApp}
        className={`whatsapp-btn ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </div>
      <div
        onClick={handleCall}
        className={`call-btn ${goUp ? "show-call" : ""}`}
      >
        <FontAwesomeIcon icon={faPhone} />
      </div>
      
    </div>
  );
}

export default Hero;
